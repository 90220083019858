/* Theme color vars */
@accentColor: rgba(50, 90, 78, 1);
@accentColor80: rgba(50, 90, 78, 0.8);
@accentColor70: rgba(50, 90, 78, 0.7);
@accentColor50: rgba(50, 90, 78, 0.5);
@accentColor40: rgba(50, 90, 78, 0.4);
@accentColor30: rgba(50, 90, 78, 0.3);
@accentColor20: rgba(50, 90, 78, 0.2);
@accentColor10: rgba(50, 90, 78, 0.1);
@accentColor075: rgba(50, 90, 78, 0.075);
@accentColor5: rgba(50, 90, 78, 0.05);
@accentColor3: rgba(50, 90, 78, 0.03);
@accentColor70Solid: #5A706B;
@accentColor10Solid: #E8EBEA;
@accentColor5Solid: #F3F4F4;

@dashboarNotifBg: #ede8df;
@menuBarColor: #3b6b5f;
@primaryColor: #235b4e;
@darkPrimary: #1B312B;

:root {
  // Colors
  // Primary
  --color-primary-50: #EBEFED;
  --color-primary-75: #DFE6E4;
  --color-primary-100: #C0CCC8;
  --color-primary-200: #A0B3AD;
  --color-primary-300: #819992;
  --color-primary-400: #618077;
  --color-primary-500: #42675B;
  --color-primary-600: #325A4E;
  --color-primary-700: #27453C;
  --color-primary-800: #1F3730;
  --color-primary-900: #172A24;
  --color-primary-1000: #0F1C18;
  --color-primary-1100: #080E0C;
}